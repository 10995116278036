import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

import { TextInput } from '../../Input/Input'
import { Button } from '../../Button/Button'
import { FMModal } from '../FMModal/FMModal'
import PaymentDone from './PaymentDone'
import { Loading } from '../../../loading/Loading'
import { PaymentLoading } from '../../../loading/PaymentLoading'

import styles from '../CreateFolderModal/CreateFolder.module.scss'

//services
import { formatPrice } from '../../../../services/internal/utils/formatPrice'
import { addToBalance, getWallet, getUserInfo, setInvoiceItem } from '../../../../services/internal/store/actions'

export interface Iprops {
  showModal?: boolean
  handleCFClose: () => void
  getUserInfo: any
  userInfo?: any
  payment?: any
  isInvoiceLoading?: any
  walletPage?: any
  loading?: any
  invoiceItem?: any
  addToBalance?: any
  setInvoiceItem?: any
}

export interface Istate {
  name?: string
  description?: string
  message?: string
  userEmail?: string
  selected?: string
  shareLink?: string
  urlInput?: string
}

class PayModal extends React.Component<Iprops, any> {
  form: any

  constructor(props: any) {
    super(props)
    this.state = {
      urlInput: '',
      price: '',
      active: 0
    }
  }

  //get wallet price
  componentDidMount() {
    this.props.getUserInfo()
  }

  //close modal
  handleClose = () => {
    this.setState({ price: '', active: 0 })
    this.props.handleCFClose()
  }

  handleChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value, newprice: e.target.value, active: 0 })
  }

  //set default prices in wallet
  setPrice = (price: number) => {
    this.setState({ price, active: price })
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.invoiceItem) {
      this.setState({
        price: (nextProps.invoiceItem.payablePrice * 1000).toFixed(0),
        payablePrice: (nextProps.invoiceItem.payablePrice * 1000).toFixed(0),
        id: nextProps.invoiceItem.id
      })
      if (nextProps.invoiceItem.payablePrice < this.props.userInfo.balance) this.setState({ id: nextProps.invoiceItem.id })

      if (this.props.userInfo.balance > 0) {
        if (nextProps.invoiceItem.payablePrice > this.props.userInfo.balance)
          this.setState({ price: Math.ceil((nextProps.invoiceItem.payablePrice - this.props.userInfo.balance) * 1000).toFixed(0) })
      }
    }
    if (nextProps.userInfo) {
      this.setState({ balance: nextProps.userInfo.balance })
    }
    if (nextProps.refObject) {
      this.setState({ refId: nextProps.refObject.RefId, postAddress: nextProps.refObject.postAddress }, () => {
        this.form.submit()
      })
    }
  }

  //form submit
  submitPayment = () => {
    let price = this.props.walletPage ? this.state.price : this.state.newprice

    // ? this.state.newprice
    // : Math.ceil(this.state.price - this.props.userInfo.balance * 1000).toFixed(0)
    this.props.addToBalance({ id: this.props.invoiceItem.id, price: this.state.price })
  }
  componentWillUnmount = () => {
    this.props.setInvoiceItem([])
  }
  render() {
    let { showModal, handleCFClose, userInfo, walletPage, loading, isInvoiceLoading } = this.props
    let { price, payablePrice } = this.state
    let isPaymentDone = !this.props.walletPage && price !== '' && payablePrice / 1000 <= userInfo.balance
    let isLoading = !price && !walletPage
    let balance = this.props.userInfo.balance ? this.props.userInfo.balance : this.state.balance

    let defaultPrice = this.props.walletPage
      ? this.state.price
      : this.state.newprice
      ? this.state.newprice
      : Math.ceil(this.state.price - this.props.userInfo.balance * 1000).toFixed(0)
    let defaultWalletPrices = [20000, 40000, 60000]
    return (
      <FMModal show={showModal} width={360} handleClose={this.handleClose} title={t`Payment`} buttonCloser={true}>
        {isLoading || isInvoiceLoading ? (
          <div className={'pg-pb-10 pg-pt-2'}>
            <Loading />
          </div>
        ) : isPaymentDone ? (
          <PaymentDone id={showModal && this.props.invoiceItem && this.props.invoiceItem.id} handleClose={handleCFClose} price={price} />
        ) : (
          <>
            <form
              method={'post'}
              action={'https://bpm.shaparak.ir/pgwchannel/startpay.mellat'}
              ref={el => (this.form = el)}
              style={{ display: 'none' }}
            >
              <input name={'RefId'} type="text" value={this.state.refId} />
              <input name={'postAddress'} type="text" value={this.state.postAddress} />
              <button type={'submit'} />
            </form>
            {!isInvoiceLoading && loading ? (
              <PaymentLoading />
            ) : (
              !isInvoiceLoading && (
                <>
                  <div className={styles.row}>
                    <div className={'pg-flex pg-justify-center pg-flex-col'}>
                      <div className={'pg-text-l pg-mb-2 pg-text-center '}>{t`Your Credit : `}</div>
                      <h2 className={'pg-text-xl  pg-text-center fa'}>{formatPrice(balance, '')}</h2>
                      <h2 className={'pg-text-l pg-mb-4 pg-text-gray-600 pg-text-center fa'}> تومان</h2>
                    </div>
                    <div className={'pg-mb-3'}>{t`Enter the credit you want to charge:`}</div>
                    {walletPage && (
                      <div className={'pg-flex pg-text-center pg-my-3'}>
                        {defaultWalletPrices.map((defaultPrice: any) => (
                          <div
                            onClick={() => this.setPrice(defaultPrice)}
                            className={`${this.state.active == defaultPrice &&
                              'pg-bg-blue-400 pg-text-white pg-cursor-default'} pg-cursor-pointer pg-rounded-sm pg-border pg-p-2 pg-w-full pg-ml-3`}
                          >
                            {formatPrice(defaultPrice / 1000)}
                          </div>
                        ))}
                      </div>
                    )}
                    <div className={'pg-relative'}>
                      <TextInput name={'price'} value={defaultPrice} onChange={this.handleChange} />
                      <span className={'pg-absolute'} style={{ top: '7px', left: '8px' }}>
                        تومان
                      </span>
                    </div>
                    <Button
                      onClick={this.submitPayment}		      
                      className={['pg-btnPrimary', 'pg-btnSm', 'pg-mt-3', 'pg-btnDisabled']}
                      style={{ width: '100%' }}
		      disabled={true}
                      type={'submit'}
                    >{t`Payment`}</Button>
<p style={{ padding: '10px 0', display:'block', textAlign: 'justify'}} >هم اکنون پرداخت از طریق درگاه امکانپذیر نیست. پس از واریز به شماره کارت :<strong>6104337540247655</strong> به نام: ابر افراز شریف تصویر تراکنش را از طریق <a className="pg-text-blue-400 pg-font-vMedium"  href="https://www.persiangig.com/support/user/tickets/new">ثبت تیکت</a> ارسال نمایید.</p>
		</div>
                </>
              )
            )}
          </>
        )}
      </FMModal>
    )
  }
}

const mapStateToProps = (state: any) => ({
  refObject: state.account.ref,
  userInfo: state.account.info,
  invoiceItem: state.account.invoiceItem,
  loading: state.loading.isLoading,
  isInvoiceLoading: state.loading.isInvoiceLoading
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    addToBalance: (value: any) => dispatch(addToBalance(value)),
    getUserInfo: () => dispatch(getUserInfo()),
    setInvoiceItem: (value: any) => dispatch(setInvoiceItem(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayModal)
